import i18n from "i18next";

export const LIST = [
  i18n.t("use:use01"),
  i18n.t("use:use02"),
  i18n.t("use:use03"),
  i18n.t("use:use04"),
  i18n.t("use:use05"),
  i18n.t("use:use06"),
  i18n.t("use:use07"),
  i18n.t("use:use08")
];
