import i18n from "i18next";

i18n.addResources("es", "use", {
  title: "¿Qué obtienes con nosotros?",
  subtitle: "Con Picash:",
  use01:
    "Visita nuestros comercios aliados, compra y/o consume pagando con QR.",
  use02:
    "Accede a los descuentos y promociones vigentes al momento de tu compra o consumo en nuestros establecimientos aliados.",
  use03:
    "¡Sencillo, no vayas a otro sitio! Desde Pica$h recarga tu celular y obtén paquetes de contenido y entretenimiento.",
  use04:
    "Viaja de una manera eficiente, ahorrando tiempo con excelentes precios.",
  use05:
    "Envía paquetes y mensajería de una manera segura con trazabilidad de tu envío.",
  use06:
    "Paga la administración de tu conjunto y asegúrate de tener conocimiento de quién te ha visitado; revisa las actas de reuniones de tu conjunto, entrada y salida de elementos del mismo.",
  use07: "Solicita por medio de nuestro Market Place servicios a domicilio.",
  use08:
    "Accede a todos los servicios para mejorar tu calidad de vida con Pilife."
});

i18n.addResources("pt", "use", {
  title: "¿O que você ganha conosco?",
  subtitle: "Com Picash:",
  use01:
    "Visite nossos comércios parceiros, compre e/ou consuma pagando com QR code.",
  use02:
    "Acesse os descontos e promoções em vigor no momento de sua compra ou consumo em nossos estabelecimentos aliados.",
  use03:
    "Simples, não vá a nenhum outro lugar! Com Pica$h, recarregue seu telefone celular e obtenha pacotes de conteúdo e entretenimento.",
  use04: "Viaje com eficiência, economizando tempo com ótimos preços.",
  use05: "Envie encomendas de maneira segura e com rastreio.",
  use06:
    "Pague seu condomínio e verifique se você conhece quem o visitou, verifique as atas das reuniões de sua entrada e saída do conjunto e saída de elementos do mesmo.",
  use07: "Solicite serviços a domicílio por meio do nosso Market Place.",
  use08:
    "Acesse todos os serviços para melhorar sua qualidade de vida com a Pilife."
});
