import {
  completeState,
  completeReducer,
  createReducer,
  onReadValue,
  onConcatenate
} from "redux-recompose";
import { actions } from "./actions";
import { INITIAL_STATE, TARGET } from "./constants";

const initialState = completeState(INITIAL_STATE, [TARGET.listPage]);

const reducerDescription = {
  primaryActions: [actions.GET, actions.SEND],
  override: {
    [actions.SET_VALUE]: onReadValue(),
    [actions.GET_SUCCESS]: onConcatenate()
  }
};

const reducer = createReducer(
  initialState,
  completeReducer(reducerDescription)
);

export default reducer;
