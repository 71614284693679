import i18n from "i18next";

export const VIDEOS = [
  {
    title: i18n.t("blog:titleVideo1"),
    url: "https://www.youtube.com/embed/Kj0HqUnXQzM",
    category: i18n.t("blog:categoryVideo")
  },
  {
    title: i18n.t("blog:titleVideo2"),
    url: "https://www.youtube.com/embed/_sB72HQVdxY",
    category: i18n.t("blog:categoryVideo")
  }
];
