import i18n from "i18next";

i18n.addResources("es", "footer", {
  address: "Dirección",
  addressDescription: "Carrera 72 #64c-67 Bogotá, Colombia",
  email: "E-mail",
  whatsapp: "Whatsapp",
  copyright: "Copyright 2019 Picash by",
  allRightsReserved: "Todos los derechos reservados"
});

i18n.addResources("pt", "footer", {
  address: "Endereço",
  copyright: "Copyright 2019 Picash por",
  allRightsReserved: "Todos os direitos reservados"
});
