import i18n from "i18next";

i18n.addResources("es", "map", {
  cardAddressName: "Dirección",
  cardAddressDescription: "Carrera 72 #64c-67 Bogotá, Colombia",
  cardEmailName: "E-mail",
  cardEmailDescription: "paolarodriguez@picap.co",
  cardWhatsappName: "WhatsApp",
  cardWhatsappDescription: "57-3008283757"
});

i18n.addResources("pt", "map", {
  cardAddressName: "Endereço"
});
