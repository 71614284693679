import React from "react";
import i18n from "i18next";
import { customRefProptype } from "~proptypes/general";
import ABOUT from "~assets/about/03.png";
import styles from "./styles.module.scss";
import { STEPS } from "./constants";

function Step({ customRef }) {
  return (
    <section id="service" className="pos-r" ref={customRef}>
      <div className={`morph-wrap ${styles.morphRotate}`}>
        <svg className="morph" width="1400" height="770" viewBox="0 0 1400 770">
          <path d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z" />
        </svg>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 image-column">
            <img className="img-fluid" src={ABOUT} alt="" />
          </div>
          <div className="col-lg-6 col-md-12 mr-auto md-mt-5">
            <div className="section-title">
              <div className="title-effect title-effect-2">
                <div className="ellipse" />
                <i className="la la-info" />
              </div>
              <h2>{i18n.t("step:title")}</h2>
            </div>
            {STEPS.map(({ children: ChildrenComponent, ...item }) => (
              <div className="work-process style-2 mb-5" key={item.number}>
                <div className="step-num-box">
                  <div className="step-icon">
                    <span>
                      <i className={item.iconClassName} />
                    </span>
                  </div>
                  <div className="step-num">{item.number}</div>
                </div>
                <div className="step-desc">
                  <h4>{item.name}</h4>
                  {item.description && (
                    <p className="m-0">{item.description}</p>
                  )}
                  {ChildrenComponent && ChildrenComponent}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

Step.propTypes = {
  customRef: customRefProptype
};

export default Step;
