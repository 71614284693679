import React from "react";
import { arrayOf } from "prop-types";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { compose, withProps } from "recompose";
import { markerProptype, centerProptype } from "~proptypes/map";
import { MAP_CENTER } from "./constants";
import Marker from "./components/Marker";

function GMap({ center, markers }) {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMap center={center} zoom={11}>
        {markers.length &&
          markers.map(marker => <Marker key={marker.id} marker={marker} />)}
      </GoogleMap>
    </div>
  );
}

GMap.defaultProps = {
  center: MAP_CENTER
};

GMap.propTypes = {
  center: centerProptype,
  markers: arrayOf(markerProptype)
};

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "500px" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  withScriptjs,
  withGoogleMap
)(GMap);
