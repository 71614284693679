import i18n from "i18next";

export const CONTACT_CARDS = [
  {
    name: i18n.t("map:cardAddressName"),
    url:
      "https://www.google.com/maps/place/Av.+Boyac%C3%A1+%2364c-67,+Bogot%C3%A1/@4.6780256,-74.104217,17z",
    description: i18n.t("map:cardAddressDescription"),
    className: "col-lg-4 col-md-12",
    iconClassName: "flaticon-paper-plane"
  },
  {
    name: i18n.t("map:cardEmailName"),
    url: "mailto:paolarodriguez@picap.co",
    description: i18n.t("map:cardEmailDescription"),
    className: "col-lg-4 col-md-6 md-mt-5",
    iconClassName: "flaticon-email"
  },
  {
    name: i18n.t("map:cardWhatsappName"),
    url: "https://wa.me/573008283757",
    description: i18n.t("map:cardWhatsappDescription"),
    className: "col-lg-4 col-md-6 md-mt-5",
    iconClassName: "flaticon-social-media"
  }
];
