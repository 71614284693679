import i18n from "i18next";
import {
  required,
  email,
  numericality,
  length,
  format
} from "redux-form-validators";

export default {
  required: required({ message: i18n.t("formError:required") }),
  email: email({ message: i18n.t("formError:email") }),
  number: numericality({ message: i18n.t("formError:number") }),
  maxLength: max =>
    length({ max, message: i18n.t("formError:maxLength", { max }) }),
  minLength: min =>
    length({ min, message: i18n.t("formError:minLength", { min }) }),
  atLeastTwoWords: format({
    with: " ",
    message: i18n.t("formError:atLeastTwoWords")
  }),
  beginAndEndWhiteSpaces: format({
    with: /^\S+(\s+\S+)*$/,
    message: i18n.t("formError:beginAndEndWhiteSpaces")
  })
};
