import React from "react";
import i18n from "i18next";
import DownloadAppButton from "~components/DownloadAppButton";
import { customRefProptype } from "~proptypes/general";
import PATTERN from "~assets/svg/09.svg";
import { LIST } from "./constants";

function Use({ customRef }) {
  return (
    <section id="use" ref={customRef}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 md-mt-5 order-lg-12">
            <div className="section-title mb-3">
              <div className="title-effect title-effect-2">
                <div className="ellipse" />
                <i className="la la-info" />
              </div>
              <h2>{i18n.t("use:title")}</h2>
            </div>
            <div className="lead">
              <b>{i18n.t("use:subtitle")}</b>
              <ul className="ml-4 mb-4">
                {LIST.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <DownloadAppButton />
          </div>
          <div className="col-lg-6 col-md-12 md-mt-5 order-lg-1">
            <img className="img-fluid w-100" src={PATTERN} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

Use.propTypes = {
  customRef: customRefProptype
};

export default Use;
