import React from "react";
import i18n from "i18next";
import { SECTIONS_IDS } from "~constants/sections";
import { scrollToProptype } from "~proptypes/general";
import BACKGROUND from "~assets/bg/03.png";
import LOGO from "~assets/logo.png";
import { LIST_DATA, SOCIAL_NETWORKS } from "./constants";
import styles from "./styles.module.scss";

function Footer({ scrollTo }) {
  return (
    <>
      <footer
        className={`footer theme2-bg bg-pos-lt ${styles.footer}`}
        data-bg-img={BACKGROUND}
      >
        <div className="primary-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="footer-logo">
                  <img
                    id="footer-logo-img"
                    src={LOGO}
                    className="img-center"
                    alt=""
                  />
                </div>
                <p className="mb-0"></p>
              </div>

              <div className="col-lg-6 col-md-12 md-mt-6 left">
                <ul className="media-icon list-unstyled ">
                  {LIST_DATA.map(item => (
                    <li key={item.label}>
                      {`${item.label}: `}
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b>{item.description}</b>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="secondary-footer">
          <div className="container">
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-lg-6 offset-lg-1 col-md-12">
                  <span>
                    {`${i18n.t("footer:copyright")} `}
                    <u>
                      <a
                        href="https://www.picap.app"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n.t("app:companyName")}
                      </a>
                    </u>
                    {` | ${i18n.t("footer:allRightsReserved")}`}
                  </span>
                </div>
                <div className="col-lg-5 col-md-8 md-mt-3">
                  <div className="footer-social">
                    <ul className="list-inline d-flex justify-content-between">
                      {SOCIAL_NETWORKS.map(item => (
                        <li key={item.name}>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.iconClassName && (
                              <i className={`mr-2 ${item.iconClassName}`} />
                            )}
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="scroll-top">
        <div className={styles.scrollTop} onClick={scrollTo(SECTIONS_IDS.HOME)}>
          <i className="flaticon-go-up-in-web" />
        </div>
      </div>
    </>
  );
}

Footer.propTypes = {
  scrollTo: scrollToProptype
};

export default Footer;
