import React from "react";
import { bool } from "prop-types";
import { videoProptype } from "~proptypes/blog";
import styles from "./styles.module.scss";

function CarouselItem({ item, isDesktop }) {
  const FRAME_HEIGHT = isDesktop ? 300 : 200;

  return (
    <div className="col-12 col-lg-6">
      <div className="post m-2">
        <iframe
          title={item.title}
          height={FRAME_HEIGHT}
          src={`${item.url}?rel=0`}
          frameBorder="0"
          allowFullScreen
        />
        <div className="post-desc pb-5">
          <div className="post-title">
            <h4>{item.title}</h4>
          </div>
        </div>
        <p
          className={`d-inline-block m-0 py-2 px-3 font-weight-bold ${styles.category}`}
        >
          {item.category}
        </p>
      </div>
    </div>
  );
}

CarouselItem.propTypes = {
  item: videoProptype,
  isDesktop: bool.isRequired
};

export default CarouselItem;
