import {
  completeTypes,
  createTypes,
  withPostSuccess,
  withPostFailure
} from "redux-recompose";
import { reset, SubmissionError } from "redux-form";
import i18n from "i18next";
import { getStores, sendStore } from "~services/businesses";
import { normalizeStores } from "~schemas/storeSchema";
import { FORM_NAME as CONTACT_FORM_NAME } from "~screens/Contact/constants";
import { TARGET, TYPES, ITEMS_PER_PAGE } from "./constants";

export const completedTypes = completeTypes(TYPES);
export const actions = createTypes(completedTypes, "@@BUSINESSES");

export const actionCreators = {
  get: () => (dispatch, getState) => {
    dispatch({
      type: actions.GET,
      target: TARGET.list,
      service: getStores,
      payload: {
        page: getState().businesses[TARGET.listPage],
        perPage: ITEMS_PER_PAGE
      },
      successSelector: ({ data }) => normalizeStores(data),
      injections: [
        withPostSuccess((dispatch, { data }) => {
          dispatch({
            type: actions.SET_VALUE,
            target: TARGET.listPage,
            payload: getState().businesses[TARGET.listPage] + 1
          });

          if (data.length === ITEMS_PER_PAGE) {
            dispatch(actionCreators.get());
          }
        })
      ]
    });
  },
  send: values => {
    return {
      type: actions.SEND,
      target: TARGET.contactForm,
      payload: values,
      service: sendStore,
      successSelector: () => i18n.t("app:formSuccess"),
      injections: [
        withPostSuccess(dispatch => {
          dispatch(reset(CONTACT_FORM_NAME));
          setTimeout(() => {
            dispatch({ type: actions.SET_VALUE, target: TARGET.contactForm });
          }, 3000);
        }),
        withPostFailure(() => {
          throw new SubmissionError({
            _error: i18n.t("formError:default")
          });
        })
      ]
    };
  }
};
