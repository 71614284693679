import { shape, number, string } from "prop-types";

export const markerProptype = shape({
  id: string,
  title: string,
  address: string,
  lat: number,
  lng: number
});

export const centerProptype = shape({
  lat: number,
  lng: number
}).isRequired;
