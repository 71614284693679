import i18n from "i18next";

i18n.addResources("es", "about", {
  title: "¿Qué es Picash?",
  description1:
    "<b>Somos una solución móvil</b> integrada a la aplicación de Picap, que te permite hacer <b>pagos de una manera sencilla y segura</b>.",
  description2:
    "Pica$h tiene una forma de uso tan fácil, que te permitirá realizar tus pagos digitales, ya sea como usuario y/o como comercio aliado."
});

i18n.addResources("pt", "about", {
  title: "¿O que é Picash?",
  description1:
    "<b>Somos uma solução móvel</b> integrada ao aplicativo da Picap, que te permite fazer <b>pagamentos de maneira segura e simples</b>.",
  description2:
    "Pica$h é fácil de usar e te permite fazer seus pagamentos de forma digital, seja como usuário ou como comércio parceiro."
});
