import { set, get } from "~services/localstorage";

const KEY = "language";

export const getLanguage = () => get(KEY);

export const setLanguage = async lng => {
  if (getLanguage() !== lng) {
    await set(KEY, lng);
    window.location.reload();
  }
};
