import i18n from "i18next";

i18n.addResources("es", "contact", {
  title: "Comercios aliados",
  subtitle: "¿Quieres ser un comercio aliado?",
  nameLabel: "Nombre y apellido",
  companyLabel: "Nombre del negocio",
  addressLabel: "Dirección",
  cityLabel: "Ciudad",
  emailLabel: "E-mail",
  phoneLabel: "Teléfono",
  namePlaceholder: "Escribe tu nombre completo",
  companyPlaceholder: "Escribe el nombre de tu negocio",
  addressPlaceholder: "Escribe la dirección de tu negocio",
  cityPlaceholder: "Escribe la ciudad de tu negocio",
  emailPlaceholder: "Escribe tu e-mail",
  phonePlaceholder: "Escribe tu teléfono",
  submitButton: "Enviar"
});

i18n.addResources("pt", "contact", {
  title: "Comércios cadastrado",
  subtitle: "¿Quer ser um comércio cadastrado?",
  nameLabel: "Nome e sobrenome",
  companyLabel: "Nome do negócio",
  addressLabel: "Endereço",
  cityLabel: "Cidade",
  phoneLabel: "Telefone",
  namePlaceholder: "Escreva seu nome completo",
  companyPlaceholder: "Escreva o nome do seu negócio",
  addressPlaceholder: "Escreva o endereço da sua negócio",
  cityPlaceholder: "Escreva a cidade da sua negócio",
  emailPlaceholder: "Escreva seu e-mail",
  phonePlaceholder: "Escreva seu telefone"
});
