import React, { useState, useEffect, createRef } from "react";
import { string } from "prop-types";
import cn from "classnames";
import { SECTIONS_IDS } from "~constants/sections";
import { scrollToProptype } from "~proptypes/general";
import { SECTIONS } from "~constants/sections";
import LOGO from "~assets/logo.png";
import LOGO_WHITE from "~assets/logo-white.png";
import DropdownLanguage from "./components/DropdownLanguage";
import styles from "./styles.module.scss";

function Header({ scrollTo, sectionActive }) {
  const [isFixed, setFixed] = useState(false);
  const [isOpenCollapsedMenu, setOpenCollapsedMenu] = useState(false);

  const headerRef = createRef();

  useEffect(() => {
    const handleScroll = event => {
      const position = -event.srcElement.body.getBoundingClientRect().top;
      const headerHeight = headerRef.current && headerRef.current.clientHeight;
      if (position > headerHeight + 40) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed, headerRef]);

  const toggleCollapsedMenu = () => {
    setOpenCollapsedMenu(!isOpenCollapsedMenu);
  };

  return (
    <header id="site-header" className="header header-2" ref={headerRef}>
      <div className="container">
        <div id="header-wrap" className={cn({ "fixed-header": isFixed })}>
          <div className="row">
            <div className="col-lg-12">
              {/* Navbar */}
              <nav
                className="navbar navbar-expand-lg justify-content-end"
                dir="rtl"
              >
                <div
                  className="navbar-brand logo pointer flex-grow-1"
                  onClick={scrollTo(SECTIONS_IDS.HOME)}
                >
                  <img
                    id="logo-white-img"
                    className="img-center"
                    src={LOGO_WHITE}
                    alt=""
                  />
                  <img
                    id="logo-img"
                    className="img-center sticky-logo"
                    src={LOGO}
                    alt=""
                  />
                </div>
                <DropdownLanguage />
                <button
                  className={cn("navbar-toggler", styles.menuButton, {
                    collapsed: !isOpenCollapsedMenu
                  })}
                  type="button"
                  data-target="#navbarNavDropdown"
                  aria-expanded={isOpenCollapsedMenu}
                  onClick={toggleCollapsedMenu}
                >
                  <span />
                  <span />
                  <span />
                </button>
                <div
                  className={cn("collapse navbar-collapse flex-grow-0", {
                    show: isOpenCollapsedMenu
                  })}
                  id="navbarNavDropdown"
                >
                  {/* Left nav */}
                  <ul
                    id="main-menu"
                    className="nav navbar-nav d-flex flex-column flex-lg-row"
                  >
                    {SECTIONS.map(
                      ({ id, name }, index) =>
                        name && (
                          <li className="nav-item" key={id}>
                            <div
                              className={cn("nav-link pointer", {
                                active: sectionActive === id,
                                [styles.navLink]: index === 0 && !isFixed
                              })}
                              onClick={scrollTo(id)}
                            >
                              {name}
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  scrollTo: scrollToProptype,
  sectionActive: string
};

export default Header;
