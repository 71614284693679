import i18n from "i18next";

i18n.addResources("es", "blog", {
  title: "Videos y Noticias",
  categoryVideo: "Video",
  titleVideo1: "¿Cómo verificar tu billetera?",
  titleVideo2: "Lleva tu tienda a otro nivel con Picash"
});

i18n.addResources("pt", "blog", {
  title: "Vídeos e Notícias",
  categoryVideo: "Vídeo",
  titleVideo1: "¿Como verificar sua carteira?",
  titleVideo2: "Leve sua loja a outro nível com o Picash"
});
