import React, { useEffect } from "react";
import { arrayOf, func } from "prop-types";
import { connect } from "react-redux";
import { customRefProptype } from "~proptypes/general";
import { markerProptype } from "~proptypes/map";
import { actionCreators as businessesActions } from "~redux/businesses/actions";
import GMap from "~components/GoogleMap";
import styles from "./styles.module.scss";
import { CONTACT_CARDS } from "./constants";

function Map({ customRef, markers, getStores }) {
  useEffect(() => getStores(), [getStores]);

  return (
    <>
      {/* Contact cards section */}
      <section
        className={`contact-info mt-5 p-0 z-index-1 ${styles.contactInfoSection}`}
        ref={customRef}
      >
        <div className="container">
          <div className="row align-items-center">
            {CONTACT_CARDS.map(item => (
              <div className={item.className} key={item.url}>
                <div className={`contact-media ${styles.contactMedia}`}>
                  <i className={item.iconClassName} />
                  <span>{item.name}</span>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.description}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Map section */}
      <section className="o-hidden p-0 custom-mt-10 z-index-0">
        <div className="container-fluid p-0">
          <div className="row align-items-center">
            <div className="col-md-12">
              <GMap markers={markers} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Map.propTypes = {
  customRef: customRefProptype,
  markers: arrayOf(markerProptype),
  getStores: func
};

const mapStateToProps = state => ({ markers: state.businesses.list });
const mapDispatchToProps = dispatch => ({
  getStores: () => dispatch(businessesActions.get())
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
