import i18n from "i18next";
import FEATURE_SEND from "~assets/feature/picash_0003_envia.png";
import FEATURE_PAY from "~assets/feature/picash_0004_paga.png";
import FEATURE_RECHARGE from "~assets/feature/picash_0001_recarga.png";
import FEATURE_WITHDRAWALS from "~assets/feature/picash_0005_retira.png";
import FEATURE_CASHING from "~assets/feature/picash_0002_cobra.svg";
import FEATURE_REQUEST from "~assets/feature/picash_0000_pide.svg";

export const FEATURES = [
  {
    image: FEATURE_SEND,
    title: i18n.t("feature:sendTitle"),
    description: i18n.t("feature:sendDescription")
  },
  {
    image: FEATURE_PAY,
    title: i18n.t("feature:payTitle"),
    description: i18n.t("feature:payDescription")
  },
  {
    image: FEATURE_RECHARGE,
    title: i18n.t("feature:rechargeTitle"),
    description: i18n.t("feature:rechargeDescription")
  },
  {
    image: FEATURE_WITHDRAWALS,
    title: i18n.t("feature:withdrawalsTitle"),
    description: i18n.t("feature:withdrawalsDescription")
  },
  {
    image: FEATURE_CASHING,
    title: i18n.t("feature:cashingTitle"),
    description: i18n.t("feature:cashingDescription")
  },
  {
    image: FEATURE_REQUEST,
    title: i18n.t("feature:requestTitle"),
    description: i18n.t("feature:requestDescription")
  }
];
