import i18n from "i18next";

i18n.addResources("es", "feature", {
  title: "¿Cómo utilizo Picash?",
  sendTitle: "Envía",
  sendDescription:
    "Si un familiar o un amigo se queda sin dinero para consumir o transportarse, puedes enviarle dinero desde tu Pica$h a su número registrado en la aplicación y el valor será cargado automáticamente. ¡Así de simple!",
  payTitle: "Paga",
  payDescription:
    "Disfruta de visitar los comercios con la promociones que estén vigentes para ti, paga con QR sin costo adicional.",
  rechargeTitle: "Recarga",
  rechargeDescription:
    "Fácil! Recarga tu Pica$h en Efecty, o por PSE. Próximamente conocerás más formas de recargar, sin costo adicional.",
  withdrawalsTitle: "Reembolso",
  withdrawalsDescription:
    "Si deseas el reembolso de tu dinero, registra tu número de documento y elige la opción que más te convenga, puedes inscribir tu cuenta corriente o de ahorros!",
  cashingTitle: "Cobra",
  cashingDescription:
    "Si eres un comercio aliado y tienes un domicilio o necesitas generar un QR para que tu cliente te cancele, entra a esta opción, coloca el monto a cobrar y el sistema te genera el código que deberá ser escaneado por tu cliente para realizar el pago por la Pica$h. ¡Sencillo!",
  requestTitle: "Pide",
  requestDescription:
    "Si eres tú el que necesita dinero para pagar por Pica$h, para consumir en un comercio aliado y/o para transportarte; simplemente utiliza esta opción y pide al número de Pica$h de tu amigo cuánto dinero necesitas, y cuando sea notificado y acepte la solicitud, tendrás tu dinero!"
});

i18n.addResources("pt", "feature", {
  title: "¿Como utilizo Picash?",
  sendTitle: "Enviar",
  sendDescription:
    "Se um familiar ou amigo estiver sem dinheiro, você pode enviá-lo através da sua conta Pica$h e o valor será enviado para a conta Pica$h dele. Simples assim!",
  payTitle: "Pagar",
  payDescription:
    "Aproveite para visitar as lojas com as promoções válidas para você, pague com QR sem nenhum custo adicional.",
  rechargeTitle: "Recarga",
  rechargeDescription:
    "Fácil! Recarregue seu Pica$h com dinheiro ou por aproximação. Em breve você conhecerá mais formas de recarregar, sem custo adicional.",
  withdrawalsDescription:
    "Se você quer seu dinheiro de volta, registre o número do documento e escolha a opção que melhor lhe convier, você poderá registrar sua conta corrente ou poupança.",
  cashingTitle: "Cobrar",
  cashingDescription:
    "Se você é um comerciante e precisa gerar um QR code para seu cliente, insira essa opção, coloque o valor a ser cobrado e o sistema gera o código que deve ser verificado por seu cliente para efetuar o pagamento pelo Pica$h. É simples!",
  requestTitle: "Pedir",
  requestDescription:
    "Se é você quem está sem dinheiro, peça a um amigo ou familiar. Você só precisa passar o número da sua Pica$h e quando ele aceitar a transação, terá seu dinheiro."
});
