import React from "react";
import i18n from "i18next";
import { customRefProptype } from "~proptypes/general";
import CarouselContainer from "./components/CarouselContainer";
import { VIDEOS } from "./constants";

function Blog({ customRef }) {
  return (
    <section id="blog" className="pos-r o-hidden" ref={customRef}>
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-8 col-md-12 ml-auto mr-auto">
            <div className="section-title">
              <div className="title-effect title-effect-2">
                <div className="ellipse"></div> <i className="la la-btc" />
              </div>
              <h2 className="title">{i18n.t("blog:title")}</h2>
            </div>
          </div>
        </div>
        <CarouselContainer arrayVideos={VIDEOS} />
      </div>
    </section>
  );
}

Blog.propTypes = {
  customRef: customRefProptype
};

export default Blog;
