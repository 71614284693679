import platform from "platform-detect";

const MOBILE_OS = { android: "android", ios: "ios" };

const DOWNLOAD_APP_URL = {
  [MOBILE_OS.android]:
    "https://play.google.com/store/apps/details?id=co.picap.passenger",
  [MOBILE_OS.ios]:
    "https://itunes.apple.com/co/app/picap-mototaxi-colombia/id1139476429?mt=8"
};

const WEB_URL = "https://www.picap.app#app-download-area";

const PLATFORM = Object.keys(platform).find(
  item =>
    platform[item] && Object.keys(MOBILE_OS).find(os => MOBILE_OS[os] === item)
);

export default PLATFORM ? DOWNLOAD_APP_URL[PLATFORM] : WEB_URL;
