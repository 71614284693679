export const SCRIPTS = {
  jquery: "js/jquery.min.js",
  popper: "js/popper.min.js",
  bootstrap: "js/bootstrap.min.js",
  appear: "js/jquery.appear.js",
  modernizr: "js/modernizr.js",
  easing: "js/jquery.easing.min.js",
  menu: "js/menu/jquery.smartmenus.js",
  owlCarousel: "js/owl-carousel/owl.carousel.min.js",
  magnificPopup: "js/magnific-popup/jquery.magnific-popup.min.js",
  counter: "js/counter/counter.js",
  countdown: "js/countdown/jquery.countdown.min.js",
  confetti: "js/confetti.js",
  morph: "js/morph.js",
  validate: "js/contact-form/jquery.validate.min.js",
  wow: "js/wow.min.js",
  themeScript: "js/theme-script.js"
};
