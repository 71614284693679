export const TARGET = {
  list: "list",
  listPage: "listPage",
  contactForm: "contactForm"
};

export const TYPES = ["GET", "SEND", "SET_VALUE"];

export const INITIAL_STATE = {
  [TARGET.list]: [],
  [TARGET.listPage]: 1,
  [TARGET.contactForm]: null
};

export const ITEMS_PER_PAGE = 100;
