import i18n from "i18next";

i18n.addResources("es", "home", {
  title1: "Descarga Picap y ",
  title2: "activa Picash",
  description:
    "Compra seguro y ahorra dinero. Haz tus pagos desde cualquier lugar de una manera <b>más segura y sencilla que el efectivo</b>"
});

i18n.addResources("pt", "home", {
  title1: "Baixe Picap e ",
  title2: "ative seu Picash",
  description:
    "Compre com segurança e economize dinheiro. Faça seus pagamentos de qualquer lugar de maneira <b>mais segura e simples</b>"
});
