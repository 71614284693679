import i18n from "i18next";

i18n.addResources("es", "step", {
  title: "Cómo empezar",
  step01Name: "Descarga la app Picap y regístrate",
  step02Name: "Ingresa a Picash y valida tus datos",
  step02Description:
    "Crea un pin de seguridad y verifica tu numero de celular registrado. ¡Listo, explórala y úsala!"
});

i18n.addResources("pt", "step", {
  title: "Como começar",
  step01Name: "Baixe o aplicativo Picap e faça seu cadastro",
  step02Name: "Entre em Pica$h e valide seus dados",
  step02Description:
    "Crie uma senha de segurança e verifique seu número de celular registrado.  Pronto! Comece a explorar e usar!"
});
