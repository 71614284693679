import i18n from "i18next";
import ES_FLAG from "~assets/svg/espanol.svg";
// import EN_FLAG from "~assets/svg/english.svg";
import PT_FLAG from "~assets/svg/portugues.svg";

export const LANGUAGES = [
  {
    id: "es",
    name: i18n.t("languages:es"),
    flag: ES_FLAG
  },
  // {
  //   id: "en",
  //   name: i18n.t("languages:en"),
  //   flag: EN_FLAG
  // },
  {
    id: "pt",
    name: i18n.t("languages:pt"),
    flag: PT_FLAG
  }
];
