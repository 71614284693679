import React from "react";
import cn from "classnames";
import APP_URL from "~constants/platform";
import i18n from "i18next";

function DownloadAppButton({ secondary }) {
  return (
    APP_URL && (
      <a
        className={cn("btn mb-2 ml-2 wow fadeInUp", {
          "btn-primary": !secondary,
          "btn-secondary": secondary
        })}
        data-wow-duration="3s"
        data-wow-delay="0.5s"
        href={APP_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n.t("app:downloadAppButton")}
      </a>
    )
  );
}

export default DownloadAppButton;
