import React from "react";
import i18n from "i18next";
import { customRefProptype } from "~proptypes/general";
import PATTERN from "~assets/svg/08.svg";

function About({ customRef }) {
  return (
    <section id="about" ref={customRef}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 order-lg-12">
            <img className="img-fluid w-100" src={PATTERN} alt="" />
          </div>
          <div className="col-lg-6 col-md-12 md-mt-5 order-lg-1">
            <div className="section-title mb-3">
              <div className="title-effect title-effect-2">
                <div className="ellipse"></div> <i className="la la-info" />
              </div>
              <h2>{i18n.t("about:title")}</h2>
            </div>
            <p
              className="lead"
              dangerouslySetInnerHTML={{ __html: i18n.t("about:description1") }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: i18n.t("about:description2") }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

About.propTypes = {
  customRef: customRefProptype
};

export default About;
