import React from "react";
import i18n from "i18next";
import { customRefProptype } from "~proptypes/general";
import PATTERN from "~assets/pattern/03.png";
import styles from "./styles.module.scss";
import { FEATURES } from "./constants";

function Feature({ customRef }) {
  return (
    <section id="start" className="pos-r text-center" ref={customRef}>
      <div className={styles.bgAnimation}>
        <img className="zoom-fade" src={PATTERN} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 ml-auto mr-auto">
            <div className="section-title">
              <div className="title-effect title-effect-2">
                <div className="ellipse" />
                <i className="la la-cubes" />
              </div>
              <h2>{i18n.t("feature:title")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {FEATURES.map(({ image, title, description }) => (
            <div className="col-lg-4 col-md-6 mb-4" key={title}>
              <div className={styles.featuredItem}>
                <div className={`featured-icon ${styles.featuredIcon}`}>
                  <img className="img-center" src={image} alt="" />
                </div>
                <div className="featured-title">
                  <h5>{title}</h5>
                </div>
                <div className="featured-desc">
                  <p>{description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

Feature.propTypes = {
  customRef: customRefProptype
};

export default Feature;
