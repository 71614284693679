import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers as CR
} from "redux";
import { fetchMiddleware, wrapCombineReducers } from "redux-recompose";
import thunk from "redux-thunk";
import { reducer as form } from "redux-form";
import businesses from "./businesses/reducer";

// Use this function to let invisible reducer override behavior when needed
const combineReducers = wrapCombineReducers(CR);

const reducers = combineReducers({
  businesses,
  form
});

const middlewares = [thunk, fetchMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(reducers, compose(...enhancers));

export default store;
