import i18n from "i18next";

// Spanish
i18n.addResources("es", "app", {
  companyName: "Picap",
  knowMoreButton: "Saber más",
  downloadAppButton: "Descárgala aquí",
  formSuccess: "Los datos fueron enviados correctamente."
});

i18n.addResources("es", "formError", {
  required: "El campo es requerido.",
  format: "El formato es inválido.",
  email: "El e-mail es invalido.",
  number: "Sólo se permiten números.",
  maxLength: "El campo debe tener máximo {{max}} caracteres.",
  minLength: "El campo debe tener mínimo {{min}} caracteres.",
  beginAndEndWhiteSpaces:
    "Caracteres de espacio al inicio y/o al final no permitidos.",
  atLeastTwoWords: "El campo debe contener al menos 2 palabras.",
  default: "Error al enviar los datos. Inténtalo nuevamente."
});

i18n.addResources("es", "sectionTitle", {
  home: "Inicio",
  about: "¿Qué es Picash?",
  feature: "¿Cómo utilizo Picash?",
  step: "¿Cómo empezar?",
  contact: "Contáctanos"
});

i18n.addResources("es", "meta", {
  title: "Picash - lo que tu tiempo necesita",
  description:
    "Somos una solución móvil integrada a la aplicación de Picap, que te permite hacer pagos de una manera sencilla y segura.",
  author: "Picap"
});

i18n.addResources("es", "languages", {
  es: "español",
  en: "inglés",
  pt: "portugués"
});

// Portuguese
i18n.addResources("pt", "app", {
  companyName: "Picap",
  knowMoreButton: "Saber mais",
  downloadAppButton: "Baixe aqui",
  formSuccess: "Os dados foram enviados corretamente."
});

i18n.addResources("pt", "formError", {
  required: "O campo é obrigatório.",
  format: "O formato é inválido.",
  email: "O e-mail é inválido.",
  number: "Somente números são permitidos.",
  maxLength: "O campo deve ter no máximo {{max}} caracteres.",
  minLength: "O campo deve ter no mínimo {{min}} caracteres.",
  beginAndEndWhiteSpaces:
    "Caracteres de espaço no início e / ou no final não são permitidos.",
  atLeastTwoWords: "O campo deve conter pelo menos 2 palavras.",
  default: "Erro ao enviar dados. Tente novamente."
});

i18n.addResources("pt", "sectionTitle", {
  home: "Inicio",
  about: "¿O que é Picash?",
  feature: "¿Como utilizar o Picash?",
  step: "¿Como começar?",
  contact: "Fale conosco"
});

i18n.addResources("pt", "meta", {
  title: "Picash - o que seu tempo precisa",
  description:
    "Somos uma solução móvel integrada ao aplicativo Picap, que permite efetuar pagamentos de forma simples e segura.",
  author: "Picap"
});

i18n.addResources("pt", "languages", {
  es: "espanhol",
  en: "inglês",
  pt: "portugues"
});
