import React from "react";
import i18n from "i18next";
import { SECTIONS_IDS } from "~constants/sections";
import { customRefProptype, scrollToProptype } from "~proptypes/general";
import DownloadAppButton from "~components/DownloadAppButton";
import PATTERN from "~assets/pattern/01.png";
import MACKBOOK from "~assets/mackbook/01.png";
import BACKGROUND from "~assets/bg/04.png";
import styles from "./styles.module.scss";

function Home({ customRef, scrollTo }) {
  return (
    <section
      id="home"
      className={`fullscreen-banner ${styles.sectionHome}`}
      data-bg-img={PATTERN}
      ref={customRef}
    >
      <div className="hero-bg">
        <img className="img-fluid bg-cover" src={BACKGROUND} alt="" />
      </div>
      <div className="align-center p-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-12 img-side">
              <img className="img-center" src={MACKBOOK} alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 md-mt-5 mr-auto">
              <h1
                className={`mb-4 wow fadeInUp font-weight-normal ${styles.title}`}
                data-wow-duration="1.5s"
              >
                {i18n.t("home:title1")}
                <span
                  className={`font-weight-bold text-uppercase ${styles.secondTitle}`}
                >
                  {i18n.t("home:title2")}
                </span>
              </h1>
              <p
                className="lead mb-4 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{ __html: i18n.t("home:description") }}
              />
              <DownloadAppButton />
              <div
                className="btn btn-secondary m-0 mb-2 wow fadeInUp"
                data-wow-duration="3s"
                data-wow-delay="0.5s"
                onClick={scrollTo(SECTIONS_IDS.ABOUT)}
              >
                {i18n.t("app:knowMoreButton")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="round-shape"></div>
    </section>
  );
}

Home.propTypes = {
  customRef: customRefProptype,
  scrollTo: scrollToProptype
};

export default Home;
