import React, { useState, useEffect } from "react";
import { arrayOf } from "prop-types";
import { Carousel } from "react-bootstrap";
import { videoProptype } from "~proptypes/blog";
import { reduceArray } from "./utils";

function CarouselContainer({ arrayVideos }) {
  const [isDesktop, setIsDesktop] = useState(true);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992 && isDesktop) {
        setIsDesktop(false);
        setIndex(0);
      } else if (window.innerWidth >= 992 && !isDesktop) {
        setIsDesktop(true);
        setIndex(0);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  const handleSelect = (selectedIndex, eventDirection) => {
    setIndex(selectedIndex);
    setDirection(eventDirection);
  };

  return (
    <Carousel
      controls={false}
      indicators={reduceArray(arrayVideos, isDesktop).length > 1}
      activeIndex={index}
      direction={direction}
      onSelect={handleSelect}
    >
      {reduceArray(arrayVideos, isDesktop).map(({ key, component: Item }) => (
        <Carousel.Item key={key}>{Item}</Carousel.Item>
      ))}
    </Carousel>
  );
}

CarouselContainer.propTypes = {
  arrayVideos: arrayOf(videoProptype)
};

export default CarouselContainer;
