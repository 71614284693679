import React from "react";
import CarouselItem from "../CarouselItem";

export const reduceArray = (arrayVideos, isDesktop) =>
  arrayVideos.reduce((acum, item, index, array) => {
    return isDesktop
      ? index % 2 === 0
        ? [
            ...acum,
            {
              key: item.url,
              component: (
                <div className="row">
                  <CarouselItem item={item} isDesktop={isDesktop} />
                  {array[index + 1] && (
                    <CarouselItem
                      item={array[index + 1]}
                      isDesktop={isDesktop}
                    />
                  )}
                </div>
              )
            }
          ]
        : [...acum]
      : [
          ...acum,
          {
            key: item.url,
            component: (
              <div className="row">
                <CarouselItem item={item} isDesktop={isDesktop} />
              </div>
            )
          }
        ];
  }, []);
