import React from "react";
import i18n from "i18next";
import { Field, reduxForm } from "redux-form";
import { func, bool, string } from "prop-types";
import { customRefProptype } from "~proptypes/general";
import FormFields from "~components/FormFields";
import { INPUT_LIST } from "./constants";

function Contact({ customRef, handleSubmit, loading, error, successMessage }) {
  return (
    <section id="contact" className="contact-1" ref={customRef}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 col-md-12 ml-auto">
            <div className="section-title">
              <div className="title-effect">
                <div className="bar bar-top" />
                <div className="bar bar-right" />
                <div className="bar bar-bottom" />
                <div className="bar bar-left" />
              </div>
              <h2>{i18n.t("contact:title")}</h2>
              <p>{i18n.t("contact:subtitle")}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {INPUT_LIST.map(item => (
                  <div className="col-md-6" key={item.name}>
                    <Field
                      label={item.label}
                      name={item.name}
                      component={FormFields.InputField}
                      placeholder={item.placeholder}
                      validate={item.validate}
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary">
                    {loading && <i className="fas fa-spinner fa-spin mr-2" />}
                    {i18n.t("contact:submitButton")}
                  </button>
                  {error && (
                    <small className="d-inline-block mr-2 text-danger">
                      {error}
                    </small>
                  )}
                  {!error && successMessage && (
                    <small className="d-inline-block mr-2 text-success">
                      {successMessage}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

Contact.propTypes = {
  customRef: customRefProptype,
  handleSubmit: func,
  loading: bool,
  error: string,
  successMessage: string
};

export default reduxForm()(Contact);
