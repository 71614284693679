import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { setLanguage, getLanguage } from "~utils/language";
import { LANGUAGES } from "./constants";
import styles from "./styles.module.scss";

function DropdownLanguage() {
  const [title] = useState(
    getLanguage() || process.env.REACT_APP_DEFAULT_LANGUAGE
  );

  const selectedLanguage = lan => LANGUAGES.find(item => item.id === lan);

  const handleOnSelect = event => {
    const lng = selectedLanguage(event).id;
    setLanguage(lng);
  };

  return (
    <Dropdown size="sm" onSelect={handleOnSelect}>
      <Dropdown.Toggle
        variant="link"
        className={`btn-transparent btn-sm ml-3 ml-lg-0 p-0 pr-3 ${styles.dropdownButton}`}
      >
        <img src={selectedLanguage(title).flag} alt="" />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        {LANGUAGES.map(item => (
          <Dropdown.Item
            className={styles.dropdownItem}
            key={item.id}
            eventKey={item.id}
          >
            <img src={item.flag} alt={item.name} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownLanguage;
