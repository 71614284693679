import i18n from "i18next";
import formValidations from "~utils/formValidations";

export const FORM_NAME = "contact";

const FIELD_NAMES = {
  NAME: "name",
  COMPANY: "company",
  ADDRESS: "address",
  CITY: "city",
  EMAIL: "email",
  PHONE: "phone"
};

export const VALIDATIONS = {
  [FIELD_NAMES.NAME]: [
    formValidations.required,
    formValidations.atLeastTwoWords,
    formValidations.maxLength(40),
    formValidations.beginAndEndWhiteSpaces
  ],
  [FIELD_NAMES.COMPANY]: [
    formValidations.required,
    formValidations.maxLength(40),
    formValidations.beginAndEndWhiteSpaces
  ],
  [FIELD_NAMES.ADDRESS]: [
    formValidations.required,
    formValidations.maxLength(40),
    formValidations.beginAndEndWhiteSpaces
  ],
  [FIELD_NAMES.CITY]: [
    formValidations.required,
    formValidations.maxLength(40),
    formValidations.beginAndEndWhiteSpaces
  ],
  [FIELD_NAMES.PHONE]: [
    formValidations.required,
    formValidations.number,
    formValidations.maxLength(10),
    formValidations.minLength(7),
    formValidations.beginAndEndWhiteSpaces
  ],
  [FIELD_NAMES.EMAIL]: [formValidations.required, formValidations.email]
};

export const INPUT_LIST = [
  {
    label: i18n.t("contact:nameLabel"),
    name: FIELD_NAMES.NAME,
    placeholder: i18n.t("contact:namePlaceholder"),
    validate: VALIDATIONS[FIELD_NAMES.NAME]
  },
  {
    label: i18n.t("contact:companyLabel"),
    name: FIELD_NAMES.COMPANY,
    placeholder: i18n.t("contact:companyPlaceholder"),
    validate: VALIDATIONS[FIELD_NAMES.COMPANY]
  },
  {
    label: i18n.t("contact:cityLabel"),
    name: FIELD_NAMES.CITY,
    placeholder: i18n.t("contact:cityPlaceholder"),
    validate: VALIDATIONS[FIELD_NAMES.CITY]
  },
  {
    label: i18n.t("contact:addressLabel"),
    name: FIELD_NAMES.ADDRESS,
    placeholder: i18n.t("contact:addressPlaceholder"),
    validate: VALIDATIONS[FIELD_NAMES.ADDRESS]
  },
  {
    label: i18n.t("contact:emailLabel"),
    name: FIELD_NAMES.EMAIL,
    placeholder: i18n.t("contact:emailPlaceholder"),
    validate: VALIDATIONS[FIELD_NAMES.EMAIL]
  },
  {
    label: i18n.t("contact:phoneLabel"),
    name: FIELD_NAMES.PHONE,
    placeholder: i18n.t("contact:phonePlaceholder"),
    validate: VALIDATIONS[FIELD_NAMES.PHONE]
  }
];
