import React, { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { markerProptype } from "~proptypes/map";
import MARKER from "~assets/marker.svg";

function GoogleMarker({ marker: { lat, lng, title, address } }) {
  const [isOpen, openMarker] = useState(false);
  const toggleOpen = () => openMarker(!isOpen);

  return (
    <Marker position={{ lat, lng }} icon={MARKER} onClick={toggleOpen}>
      {isOpen && (
        <InfoWindow onCloseClick={toggleOpen}>
          <div className="pt-2 pr-4 text-left">
            <h5 className="m-0">{title}</h5>
            <p>{address}</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}

GoogleMarker.propTypes = {
  marker: markerProptype
};

export default GoogleMarker;
