import i18n from "i18next";

export const LIST_DATA = [
  {
    label: i18n.t("footer:address"),
    description: i18n.t("footer:addressDescription"),
    url:
      "https://www.google.com/maps/place/Av.+Boyac%C3%A1+%2364c-67,+Bogot%C3%A1/@4.6780256,-74.104217,17z"
  },
  {
    label: i18n.t("footer:email"),
    description: "paolarodriguez@picap.co",
    url: "mailto:paolarodriguez@picap.co"
  },
  {
    label: i18n.t("footer:whatsapp"),
    description: "+57-3008283757",
    url: "https://wa.me/573008283757"
  }
];

export const SOCIAL_NETWORKS = [
  {
    name: "Facebook",
    url: "https://es-la.facebook.com/picapco/",
    iconClassName: "fab fa-facebook-f"
  },
  {
    name: "Twitter",
    url: "https://twitter.com/picapco",
    iconClassName: "fab fa-twitter"
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/picap_col",
    iconClassName: "fab fa-instagram"
  }
];
