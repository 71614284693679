import i18n from "i18next";
import { createRef } from "react";
import Home from "~screens/Home";
import About from "~screens/About";
import Use from "~screens/Use";
import Feature from "~screens/Feature";
import Step from "~screens/Step";
import Contact from "~screens/Contact";
import GoogleMap from "~screens/Map";
import Blog from "~screens/Blog";

const HOME_REF = createRef();
const ABOUT_REF = createRef();
const USE_REF = createRef();
const FEATURE_REF = createRef();
const STEP_REF = createRef();
const CONTACT_REF = createRef();
const MAP_REF = createRef();
const BLOG_REF = createRef();

export const SECTIONS_IDS = {
  HOME: "home",
  ABOUT: "about",
  USE: "use",
  FEATURE: "feature",
  STEP: "step",
  CONTACT: "contact",
  MAP: "map",
  BLOG: "blog"
};

export const SECTIONS = [
  {
    id: SECTIONS_IDS.HOME,
    component: Home,
    name: i18n.t("sectionTitle:home"),
    ref: HOME_REF
  },
  {
    id: SECTIONS_IDS.ABOUT,
    component: About,
    name: i18n.t("sectionTitle:about"),
    ref: ABOUT_REF
  },
  { id: SECTIONS_IDS.USE, component: Use, ref: USE_REF },
  {
    id: SECTIONS_IDS.FEATURE,
    component: Feature,
    name: i18n.t("sectionTitle:feature"),
    ref: FEATURE_REF
  },
  {
    id: SECTIONS_IDS.STEP,
    component: Step,
    name: i18n.t("sectionTitle:step"),
    ref: STEP_REF
  },
  {
    id: SECTIONS_IDS.CONTACT,
    component: Contact,
    name: i18n.t("sectionTitle:contact"),
    ref: CONTACT_REF
  },
  { id: SECTIONS_IDS.MAP, component: GoogleMap, ref: MAP_REF },
  { id: SECTIONS_IDS.BLOG, component: Blog, ref: BLOG_REF }
];

export const REFERENCES = SECTIONS.reduce(
  (acum, item) =>
    acum ? { ...acum, [item.id]: item.ref } : { [item.id]: item.ref },
  {}
);
