import React from "react";
import { validateForm } from "redux-form-validators";
import { func, bool, string } from "prop-types";
import { connect } from "react-redux";
import { actionCreators as businessesActions } from "~redux/businesses/actions";
import { customRefProptype } from "~proptypes/general";
import Contact from "./layout";
import { FORM_NAME, VALIDATIONS } from "./constants";

function ContactContainer({ customRef, sendStore, loading, successMessage }) {
  return (
    <Contact
      customRef={customRef}
      form={FORM_NAME}
      onSubmit={sendStore}
      loading={loading}
      validate={validateForm(VALIDATIONS)}
      successMessage={successMessage}
    />
  );
}

ContactContainer.propTypes = {
  customRef: customRefProptype,
  sendStore: func,
  loading: bool,
  successMessage: string
};

const mapStateToProps = state => ({
  successMessage: state.businesses.contactForm,
  loading: state.businesses.contactFormLoading
});

const mapDispatchToProps = dispatch => ({
  sendStore: values => dispatch(businessesActions.send(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
