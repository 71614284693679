import api from "~config/api";

export const getStores = ({ page, perPage }) =>
  api.get("api/web/stores/picash_public_index", {
    per_page: perPage,
    page,
    t: ""
  });
export const sendStore = data =>
  api.post("/api/web/landing/create_potential_client", data);
