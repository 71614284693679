import React from "react";
import i18n from "i18next";
import DownloadAppButton from "~components/DownloadAppButton";

export const STEPS = [
  {
    number: "01",
    name: i18n.t("step:step01Name"),
    children: <DownloadAppButton secondary />,
    iconClassName: "la la-lightbulb-o"
  },
  {
    number: "02",
    name: i18n.t("step:step02Name"),
    description: i18n.t("step:step02Description"),
    iconClassName: "la la-rocket"
  }
];
