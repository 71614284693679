import React from "react";
import i18n from "i18next";
import MetaTags from "react-meta-tags";

function MetaTagsContainer() {
  return (
    <MetaTags>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" content={i18n.t("meta:description")} />
      <meta name="author" content={i18n.t("meta:author")} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <title>{i18n.t("meta:title")}</title>
    </MetaTags>
  );
}

export default MetaTagsContainer;
