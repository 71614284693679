import React from "react";
import cn from "classnames";
import { inputProptype } from "~proptypes/form";

function InputField({ label, type, className, placeholder, meta, input }) {
  return (
    <div className="form-group">
      {label && <label>{label}</label>}
      <input
        {...input}
        type={type}
        className={cn("form-control text-right", { className: !!className })}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && (
        <small className="help-block with-errors">{meta.error}</small>
      )}
    </div>
  );
}

InputField.defaultProps = {
  type: "text",
  placeholder: ""
};

InputField.propTypes = {
  ...inputProptype
};

export default InputField;
