import React, { useState, useEffect } from "react";
import { SECTIONS, SECTIONS_IDS, REFERENCES } from "~constants/sections";
import { SCRIPTS } from "~constants/templateScripts";
import Header from "~components/Header";
import Footer from "~components/Footer";

function App() {
  const [sectionActive, setsectionActive] = useState(SECTIONS_IDS.HOME);

  useEffect(() => {
    Object.values(SCRIPTS).forEach(value => {
      const script = document.createElement("script");
      script.async = true;
      script.src = value;
      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    const handleScroll = event => {
      const position = -1 * event.srcElement.body.getBoundingClientRect().top;
      let active = sectionActive;
      SECTIONS.forEach(({ name, id }) => {
        if (
          name &&
          REFERENCES[id] &&
          REFERENCES[id].current &&
          position >= REFERENCES[id].current.offsetTop
        ) {
          active = id;
        }
      });
      setsectionActive(active);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionActive]);

  const scrollToId = id => () => {
    if (REFERENCES[id] && REFERENCES[id].current) {
      window.scrollTo({
        top: REFERENCES[id].current.offsetTop,
        behavior: "smooth"
      });
    }
  };

  return (
    <div className="App">
      <Header scrollTo={scrollToId} sectionActive={sectionActive} />
      {SECTIONS.map(({ id, ref, component: Element }) => (
        <Element key={id} customRef={ref} scrollTo={scrollToId} />
      ))}
      <Footer scrollTo={scrollToId} />
    </div>
  );
}

export default App;
