import i18next from "i18next";
import { getLanguage } from "~utils/language";

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

i18next.init({
  fallbackLng: getLanguage()
    ? [getLanguage(), process.env.REACT_APP_DEFAULT_LANGUAGE]
    : process.env.REACT_APP_DEFAULT_LANGUAGE,
  initImmediate: true,
  react: {
    wait: true
  }
});

requireAll(require.context("../../../", true, /i18n\.js$/));
